import React, { useLayoutEffect, useState, useEffect } from "react";
import { auth_routes, routes } from "./routes";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/theme.scss";
import "./assets/custom_scss/customscss.scss";
// import "react-chatbot-kit/build/main.css";
import { useSelector, useDispatch } from "react-redux";
import Layout from "./components/layout"; 
import TrackingOrder from "./screens/track/trackingPage/TrackingOrder"; 
import { setPermission } from "./store/permissions/Permissions";

const App = () => { 
  const userData = useSelector((state) => state.authentication.userdetails); 
  const setpermission = useSelector(state => state.permissions.setpermission);
  const access_token = useSelector(state => state.authentication.access_token);
  const navigation = useSelector(state => state.permissions.navigation_list)
  const userpermissions = useSelector((state) => state.authentication.userpermission); 


  const [s_route, sets_route] = useState(auth_routes); 

  useLayoutEffect(() => {
    if (userData !== null &&
      Object.keys(userData).length !== 0 &&
      access_token !== '' &&
      setpermission) { 
      sets_route(routes);
    } else {
      sets_route(auth_routes);
    }
  }, [userData,routes,access_token,setpermission,navigation,userpermissions]); 


  return (
    <>
      <Routes>
        {userData !== null && Object.keys(userData)?.length !== 0  ? ( 
          <Route path="/" element={<Layout />}>
            {s_route.map((item, index) => {
              return (
                <Route path={item.path} element={item.element} key={index} />
              );
            })}
          </Route>
        ) : (
          <>
            {s_route.map((item, index) => {
              return (
                <Route path={item.path} element={item.element} key={index} />
              );
            })}
          </>
        )}
         <Route
          path={"/track/trackingPage/TrackingOrder"}
          element={<TrackingOrder />}
        />  
      </Routes>
    </>
  );
};

export default App;
